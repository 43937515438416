.App {
  text-align: center;
}

.background-image {
  background-image:url("Cover.jpg");
  background-repeat: no-repeat;

  filter: blur(8px);
  -webkit-filter: blur(8px);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #282c34;
}

.App-logo {
  height: 60vmin;
  margin-bottom: -30px;
  margin-top: -5%;
}

.App-header {
  background-color: rgba(#282c34, .8);
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 100%;
}

.App-link {
  color: #09d3ac;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #09d3ac;
  fill: #09d3ac;

  >div {
    min-width: 150px;
    padding: 2px;
    border-radius: 10px;

    &:hover {
      background-color: rgba(#ccc, .05);
      cursor: pointer;
    }
  }
}
